// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Hr from "../../../../styleguide/components/Hr/Hr.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as ReactForm from "../../../../libs/ReactForm.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RadioButton from "../../../../styleguide/forms/RadioButton/RadioButton.res.js";
import * as $$Notification from "../../../../bindings/Notification.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import AddDays from "date-fns/addDays";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as ReactNotifications from "react-notifications";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateCloudProjectIaasCloudServersConfigurationForm from "./CreateCloudProjectIaasCloudServersConfigurationForm.res.js";
import * as CreateCloudProjectIaasCloudServersConfigurationScss from "./CreateCloudProjectIaasCloudServersConfiguration.scss";

var css = CreateCloudProjectIaasCloudServersConfigurationScss;

function domId(field, at) {
  return "cloud-project-iaas-cloud-servers-configuration-" + (field + ("-" + String(at)));
}

function serverInstanceDomId(field, at) {
  return domId("server-instance-" + field, at);
}

function volumeDomId(field, param) {
  return domId("volume", param[0]) + (field + ("-" + String(param[1])));
}

function configurationTitle(form, at) {
  var comma = ", ";
  var prefix = "#" + (String(at + 1 | 0) + Char.mdash);
  var validators = CreateCloudProjectIaasCloudServersConfigurationForm.validators.configurations.fields;
  var x = form.configurationServerInstanceVmNumberResult(at);
  var result = x !== undefined ? x : validators.serverInstance.vmNumber.validate(form.input, at);
  var vm;
  vm = result.TAG === "Ok" ? String(result._0) + (" " + (Char.times + " ")) : undefined;
  var x$1 = form.configurationServerInstanceCpuResult(at);
  var result$1 = x$1 !== undefined ? x$1 : validators.serverInstance.cpu.validate(form.input, at);
  var cpu;
  if (result$1.TAG === "Ok") {
    var x$2 = result$1._0;
    cpu = x$2 !== 1 ? String(x$2) + " vCPUs" : "1 vCPU";
  } else {
    cpu = undefined;
  }
  var x$3 = form.configurationServerInstanceRamResult(at);
  var result$2 = x$3 !== undefined ? x$3 : validators.serverInstance.ram.validate(form.input, at);
  var ram;
  ram = result$2.TAG === "Ok" ? result$2._0 + " RAM" : undefined;
  var x$4 = form.configurationServerInstanceOperatingSystemResult(at);
  var result$3 = x$4 !== undefined ? x$4 : validators.serverInstance.operatingSystem.validate(form.input, at);
  var os;
  os = result$3.TAG === "Ok" ? result$3._0 : undefined;
  var x$5 = form.configurationServerInstanceVisibilityResult(at);
  var result$4 = x$5 !== undefined ? x$5 : validators.serverInstance.visibility.validate(form.input, at);
  var visibility;
  visibility = result$4.TAG === "Ok" ? result$4._0 : undefined;
  var x$6 = form.configurationServerInstanceAvailabilityResult(at);
  var result$5 = x$6 !== undefined ? x$6 : validators.serverInstance.availability.validate(form.input, at);
  var availability;
  availability = result$5.TAG === "Ok" ? result$5._0 : undefined;
  var cpuRamOs = Belt_Array.reduce([
          cpu,
          ram,
          os
        ], [], (function (acc, item) {
            if (item !== undefined) {
              acc.push(item);
              return acc;
            } else {
              return acc;
            }
          })).join(comma);
  var constructed = cpuRamOs === "" ? "" : (
      vm !== undefined ? vm + cpuRamOs : cpuRamOs
    );
  if (constructed === "") {
    return prefix + "IaaS Cloud Server Configuration";
  } else if (visibility !== undefined) {
    if (availability !== undefined) {
      return prefix + (constructed + (comma + (visibility + (comma + availability))));
    } else {
      return prefix + (constructed + (comma + visibility));
    }
  } else if (availability !== undefined) {
    return prefix + (constructed + (comma + availability));
  } else {
    return prefix + constructed;
  }
}

function add(x, status) {
  return Belt_Array.concat(x, [status]);
}

function remove(x, index) {
  return Belt_Array.keepWithIndex(x, (function (param, idx) {
                return index !== idx;
              }));
}

function toggle(x, index) {
  return Belt_Array.mapWithIndex(x, (function (idx, visibility) {
                if (index === idx) {
                  return Visibility.toggle(visibility);
                } else {
                  return visibility;
                }
              }));
}

function expandAll(x) {
  return Belt_Array.map(x, (function (param) {
                return "Shown";
              }));
}

var Visibilities = {
  add: add,
  remove: remove,
  toggle: toggle,
  expandAll: expandAll
};

function validate(form) {
  Belt_Array.forEachWithIndex(form.input.configurations, (function (ix, param) {
          form.blurConfigurationStartDate(ix);
        }));
}

function results(form) {
  return Belt_Array.mapWithIndex(form.input.configurations, (function (ix, param) {
                return form.configurationStartDateResult(ix);
              }));
}

function isValid(results) {
  return Belt_Array.every(results, (function (result) {
                if (result !== undefined && result.TAG === "Ok") {
                  return true;
                } else {
                  return false;
                }
              }));
}

var ValidateSaveAndContinueLaterForm = {
  validate: validate,
  results: results,
  isValid: isValid
};

var persistDraft = Debounce.make(900, (function (param) {
        if (param[1].TAG === "Draft") {
          return $$Promise.wait(Api.saveCloudProjectIaasCloudServersConfigurationDraft(param[0]), (function (x) {
                        if (x.TAG === "Ok") {
                          return $$Notification.NotificationManager.success("Draft saved!", "", 1000);
                        }
                        SentryLogger.error1({
                              rootModule: "CreateCloudProjectIaasCloudServersConfiguration",
                              subModulePath: {
                                hd: "Api",
                                tl: /* [] */0
                              },
                              value: "persistDraft",
                              fullPath: "CreateCloudProjectIaasCloudServersConfiguration.Api.persistDraft"
                            }, "SaveCloudProjectIaasCloudServersConfigurationDraft::Error", [
                              "Error",
                              x._0
                            ]);
                        $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                      }));
        }
        
      }));

function submitForm(data, context, onFailure) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveCloudProjectIaasCloudServersConfiguration(data), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.Cloud.Provider.newSelector("IaasServer"));
                  }
                  SentryLogger.error1({
                        rootModule: "CreateCloudProjectIaasCloudServersConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "submitForm",
                        fullPath: "CreateCloudProjectIaasCloudServersConfiguration.Api.submitForm"
                      }, "SaveCloudProjectIaasCloudServersConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  onFailure();
                  $$Notification.NotificationManager.error("Something went wrong", "", 1000);
                }));
  }
  var projectId = context.projectId;
  $$Promise.wait(Api.updateCloudProjectIaasCloudServersConfiguration(projectId, data), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.Cloud.Provider.editSelector(projectId, "IaasServer"));
          }
          SentryLogger.error1({
                rootModule: "CreateCloudProjectIaasCloudServersConfiguration",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "submitForm",
                fullPath: "CreateCloudProjectIaasCloudServersConfiguration.Api.submitForm"
              }, "UpdateCloudProjectIaasCloudServersConfiguration::Error", [
                "Error",
                x._0
              ]);
          onFailure();
          $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
        }));
}

function saveDraftProjectAndContinueLater(form, context) {
  if (context.TAG !== "Draft") {
    return $$Promise.wait(Api.updateCloudIaasServersDraftProjectAndContinueLater(context.projectId, form.input), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateCloudProjectIaasCloudServersConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateCloudProjectIaasCloudServersConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "UpdateCloudProjectIaasServersCloudConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
                }));
  }
  validate(form);
  if (isValid(results(form))) {
    return $$Promise.wait(Api.saveCloudDraftProjectAndContinueLater("Cloud", {
                    TAG: "Cloud",
                    _0: "IaasServer"
                  }, "Draft"), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateCloudProjectIaasCloudServersConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateCloudProjectIaasCloudServersConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  
}

var Api$1 = {
  persistDraft: persistDraft,
  submitForm: submitForm,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
};

function CreateCloudProjectIaasCloudServersConfiguration(props) {
  var userLoginStatus = props.userLoginStatus;
  var mobile = props.mobile;
  var options = props.options;
  var context = props.context;
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var initialInput = React.useMemo((function () {
          if (context.TAG !== "Draft") {
            return {
                    configurations: context.data
                  };
          }
          var data = context.data;
          if (data !== undefined) {
            return {
                    configurations: data
                  };
          } else {
            return {
                    configurations: [CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.empty(options.instanceVisibility, options.instanceAvailability)]
                  };
          }
        }), []);
  var form = CreateCloudProjectIaasCloudServersConfigurationForm.useForm(initialInput, (function (output, form) {
          submitForm(output, context, form.notifyOnFailure);
        }));
  var initialState = React.useMemo((function () {
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            if (data$1 === undefined) {
              return {
                      panelsVisibility: ["Shown"],
                      volumesVisibility: ["Shown"],
                      featuresVisibility: ["Hidden"],
                      certificationsVisibility: ["Hidden"],
                      billingTypesVisibility: ["Hidden"],
                      additionalRequirementsVisibility: ["Hidden"],
                      userLoginStatus: userLoginStatus
                    };
            }
            data = data$1;
          } else {
            data = context.data;
          }
          return {
                  panelsVisibility: Belt_Array.map(data, (function (param) {
                          return "Shown";
                        })),
                  volumesVisibility: Belt_Array.map(data, (function (param) {
                          return "Shown";
                        })),
                  featuresVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  certificationsVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  billingTypesVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  additionalRequirementsVisibility: Belt_Array.map(data, (function (param) {
                          return "Hidden";
                        })),
                  userLoginStatus: userLoginStatus
                };
        }), []);
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "AddConfigurationPanel") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        panelsVisibility: Belt_Array.concat(state.panelsVisibility, ["Shown"]),
                        volumesVisibility: Belt_Array.concat(state.volumesVisibility, ["Shown"]),
                        featuresVisibility: Belt_Array.concat(state.featuresVisibility, ["Hidden"]),
                        certificationsVisibility: Belt_Array.concat(state.certificationsVisibility, ["Hidden"]),
                        billingTypesVisibility: Belt_Array.concat(state.billingTypesVisibility, ["Hidden"]),
                        additionalRequirementsVisibility: Belt_Array.concat(state.additionalRequirementsVisibility, ["Hidden"]),
                        userLoginStatus: state.userLoginStatus
                      },
                      _1: (function (param) {
                          form.addConfiguration(CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.empty(options.instanceVisibility, options.instanceAvailability));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        panelsVisibility: Belt_Array.map(state.panelsVisibility, (function (param) {
                                return "Shown";
                              })),
                        volumesVisibility: Belt_Array.map(state.volumesVisibility, (function (param) {
                                return "Shown";
                              })),
                        featuresVisibility: Belt_Array.map(state.featuresVisibility, (function (param) {
                                return "Shown";
                              })),
                        certificationsVisibility: Belt_Array.map(state.certificationsVisibility, (function (param) {
                                return "Shown";
                              })),
                        billingTypesVisibility: Belt_Array.map(state.billingTypesVisibility, (function (param) {
                                return "Shown";
                              })),
                        additionalRequirementsVisibility: Belt_Array.map(state.additionalRequirementsVisibility, (function (param) {
                                return "Shown";
                              })),
                        userLoginStatus: state.userLoginStatus
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "RemoveConfigurationPanel" :
                var at = action.at;
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: remove(state.panelsVisibility, at),
                          volumesVisibility: remove(state.volumesVisibility, at),
                          featuresVisibility: remove(state.featuresVisibility, at),
                          certificationsVisibility: remove(state.certificationsVisibility, at),
                          billingTypesVisibility: remove(state.billingTypesVisibility, at),
                          additionalRequirementsVisibility: remove(state.additionalRequirementsVisibility, at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationPanelVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: toggle(state.panelsVisibility, action.at),
                          volumesVisibility: state.volumesVisibility,
                          featuresVisibility: state.featuresVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          billingTypesVisibility: state.billingTypesVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationVolumesVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          volumesVisibility: toggle(state.volumesVisibility, action.at),
                          featuresVisibility: state.featuresVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          billingTypesVisibility: state.billingTypesVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationFeaturesVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          volumesVisibility: state.volumesVisibility,
                          featuresVisibility: toggle(state.featuresVisibility, action.at),
                          certificationsVisibility: state.certificationsVisibility,
                          billingTypesVisibility: state.billingTypesVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationCertificationsVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          volumesVisibility: state.volumesVisibility,
                          featuresVisibility: state.featuresVisibility,
                          certificationsVisibility: toggle(state.certificationsVisibility, action.at),
                          billingTypesVisibility: state.billingTypesVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationBillingTypesVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          volumesVisibility: state.volumesVisibility,
                          featuresVisibility: state.featuresVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          billingTypesVisibility: toggle(state.billingTypesVisibility, action.at),
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationAdditionalRequirementsVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          volumesVisibility: state.volumesVisibility,
                          featuresVisibility: state.featuresVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          billingTypesVisibility: state.billingTypesVisibility,
                          additionalRequirementsVisibility: toggle(state.additionalRequirementsVisibility, action.at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleLoginStatus" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          volumesVisibility: state.volumesVisibility,
                          featuresVisibility: state.featuresVisibility,
                          certificationsVisibility: state.certificationsVisibility,
                          billingTypesVisibility: state.billingTypesVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          userLoginStatus: action._0
                        },
                        _1: (function (param) {
                            saveDraftProjectAndContinueLater(form, context);
                          })
                      };
            
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = state.userLoginStatus;
  var tmp;
  if (match$2 === "LoggedIn") {
    var tmp$1;
    tmp$1 = context.TAG === "Draft" ? "Next" : "Save and Next";
    var match$3 = form.status;
    var tmp$2;
    tmp$2 = typeof match$3 !== "object" || match$3.TAG !== "Submitting" ? "Enabled" : "Busy";
    tmp = [
      tmp$1,
      (function () {
          form.submit();
        }),
      tmp$2
    ];
  } else {
    tmp = [
      "Next",
      (function () {
          toggleSignInModal(function (param) {
                return Visibility.toggle(signInModal);
              });
        }),
      "Enabled"
    ];
  }
  var tmp$3;
  tmp$3 = context.TAG === "Draft" ? [
      "Back",
      (function () {
          Url.visit(Routes_Project.Cloud.$$new);
        }),
      "Enabled"
    ] : [
      "Cancel",
      (function () {
          Url.visit(Routes_Project.index);
        }),
      "Enabled"
    ];
  var match$4 = state.userLoginStatus;
  var tmp$4;
  tmp$4 = match$4 === "LoggedIn" && props.projectStatus === "Draft" ? [
      "Save and Continue Later",
      (function () {
          saveDraftProjectAndContinueLater(form, context);
        })
    ] : undefined;
  var tmp$5;
  tmp$5 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
              Url.visit(Routes_Project.IaasServer.$$new);
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Step 1: Configure Your Cloud Servers",
                                    className: css.title
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        JsxRuntime.jsx(ProjectWizard.H2.make, {
                              children: "Select Your Compute, Storage and Network Options"
                            }),
                        JsxRuntime.jsx("div", {
                              children: Belt_Array.mapWithIndex(form.input.configurations, (function (configurationIndex, configuration) {
                                      var visibility = state.panelsVisibility[configurationIndex];
                                      var match = form.input.configurations;
                                      var tmp;
                                      if (visibility === "Shown") {
                                        var domId$1 = domId("start-date", configurationIndex);
                                        var result = form.configurationStartDateResult(configurationIndex);
                                        var tmp$1;
                                        tmp$1 = result !== undefined && result.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$2;
                                        tmp$2 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$2 = domId("contract-length", configurationIndex);
                                        var result$1 = form.configurationContractLengthResult(configurationIndex);
                                        var tmp$3;
                                        tmp$3 = result$1 !== undefined && result$1.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$4;
                                        tmp$4 = result$1 !== undefined && result$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$1._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$3 = serverInstanceDomId("vm-number", configurationIndex);
                                        var result$2 = form.configurationServerInstanceVmNumberResult(configurationIndex);
                                        var tmp$5;
                                        tmp$5 = result$2 !== undefined && result$2.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$6;
                                        tmp$6 = result$2 !== undefined && result$2.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$2._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$4 = serverInstanceDomId("cpu", configurationIndex);
                                        var result$3 = form.configurationServerInstanceCpuResult(configurationIndex);
                                        var tmp$7;
                                        tmp$7 = result$3 !== undefined && result$3.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$8;
                                        tmp$8 = result$3 !== undefined && result$3.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$3._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$5 = serverInstanceDomId("ram", configurationIndex);
                                        var result$4 = form.configurationServerInstanceRamResult(configurationIndex);
                                        var tmp$9;
                                        tmp$9 = result$4 !== undefined && result$4.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$10;
                                        tmp$10 = result$4 !== undefined && result$4.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$4._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$6 = serverInstanceDomId("operating-system", configurationIndex);
                                        var result$5 = form.configurationServerInstanceOperatingSystemResult(configurationIndex);
                                        var tmp$11;
                                        tmp$11 = result$5 !== undefined && result$5.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$12;
                                        tmp$12 = result$5 !== undefined && result$5.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$5._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$7 = serverInstanceDomId("image", configurationIndex);
                                        var result$6 = form.configurationServerInstanceImageResult(configurationIndex);
                                        var tmp$13;
                                        tmp$13 = result$6 !== undefined && result$6.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$14;
                                        tmp$14 = result$6 !== undefined && result$6.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$6._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$8 = serverInstanceDomId("hypervisor", configurationIndex);
                                        var result$7 = form.configurationServerInstanceHypervisorResult(configurationIndex);
                                        var tmp$15;
                                        tmp$15 = result$7 !== undefined && result$7.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$16;
                                        tmp$16 = result$7 !== undefined && result$7.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$7._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$9 = serverInstanceDomId("database", configurationIndex);
                                        var result$8 = form.configurationServerInstanceDatabaseResult(configurationIndex);
                                        var tmp$17;
                                        tmp$17 = result$8 !== undefined && result$8.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$18;
                                        tmp$18 = result$8 !== undefined && result$8.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$8._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$10 = serverInstanceDomId("region", configurationIndex);
                                        var result$9 = form.configurationServerInstanceRegionResult(configurationIndex);
                                        var tmp$19;
                                        tmp$19 = result$9 !== undefined && result$9.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$20;
                                        tmp$20 = result$9 !== undefined && result$9.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$9._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$11 = serverInstanceDomId("type", configurationIndex);
                                        var result$10 = form.configurationServerInstanceTypeResult(configurationIndex);
                                        var tmp$21;
                                        tmp$21 = result$10 !== undefined && result$10.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$22;
                                        tmp$22 = result$10 !== undefined && result$10.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$10._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$12 = serverInstanceDomId("description", configurationIndex);
                                        var result$11 = form.configurationServerInstanceDescriptionResult(configurationIndex);
                                        var tmp$23;
                                        tmp$23 = result$11 !== undefined && result$11.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$24;
                                        tmp$24 = result$11 !== undefined && result$11.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$11._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var match$1 = state.volumesVisibility[configurationIndex];
                                        var tmp$25;
                                        tmp$25 = match$1 === "Shown" ? JsxRuntime.jsxs("div", {
                                                children: [
                                                  Belt_Array.mapWithIndex(configuration.volumes, (function (volumeIndex, volume) {
                                                          var match = configuration.volumes;
                                                          var domId = volumeDomId("disk-type", [
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var result = form.configurationVolumeDiskTypeResult([
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var tmp;
                                                          tmp = result !== undefined && result.TAG !== "Ok" ? css.errorLabel : "";
                                                          var tmp$1;
                                                          tmp$1 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                  children: result._0,
                                                                  className: css.errorMessage
                                                                }) : null;
                                                          var domId$1 = volumeDomId("disk-size", [
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var result$1 = form.configurationVolumeDiskSizeResult([
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var tmp$2;
                                                          tmp$2 = result$1 !== undefined && result$1.TAG !== "Ok" ? css.errorLabel : "";
                                                          var tmp$3;
                                                          tmp$3 = result$1 !== undefined && result$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                  children: result$1._0,
                                                                  className: css.errorMessage
                                                                }) : null;
                                                          var domId$2 = volumeDomId("max-iops", [
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var result$2 = form.configurationVolumeMaxIopsResult([
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var tmp$4;
                                                          tmp$4 = result$2 !== undefined && result$2.TAG !== "Ok" ? css.errorLabel : "";
                                                          var tmp$5;
                                                          tmp$5 = result$2 !== undefined && result$2.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                  children: result$2._0,
                                                                  className: css.errorMessage
                                                                }) : null;
                                                          var valueDomId = volumeDomId("throughput", [
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var unitDomId = volumeDomId("throughput-unit", [
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var valueResult = form.configurationVolumeThroughputResult([
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var unitResult = form.configurationVolumeThroughputUnitResult([
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var tmp$6;
                                                          var exit = 0;
                                                          if (valueResult !== undefined) {
                                                            if (valueResult.TAG === "Ok") {
                                                              exit = 1;
                                                            } else {
                                                              tmp$6 = {
                                                                TAG: "Error",
                                                                _0: undefined
                                                              };
                                                            }
                                                          } else if (unitResult !== undefined) {
                                                            exit = 1;
                                                          } else {
                                                            tmp$6 = undefined;
                                                          }
                                                          if (exit === 1) {
                                                            tmp$6 = unitResult !== undefined && unitResult.TAG !== "Ok" ? ({
                                                                  TAG: "Error",
                                                                  _0: undefined
                                                                }) : undefined;
                                                          }
                                                          var tmp$7;
                                                          var exit$1 = 0;
                                                          if (valueResult !== undefined) {
                                                            if (valueResult.TAG === "Ok") {
                                                              exit$1 = 1;
                                                            } else {
                                                              tmp$7 = css.errorLabel;
                                                            }
                                                          } else if (unitResult !== undefined) {
                                                            exit$1 = 1;
                                                          } else {
                                                            tmp$7 = "";
                                                          }
                                                          if (exit$1 === 1) {
                                                            tmp$7 = unitResult !== undefined && unitResult.TAG !== "Ok" ? css.errorLabel : "";
                                                          }
                                                          var tmp$8;
                                                          tmp$8 = valueResult !== undefined && valueResult.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                  children: valueResult._0,
                                                                  className: css.errorMessage
                                                                }) : null;
                                                          var tmp$9;
                                                          tmp$9 = unitResult !== undefined && unitResult.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                  children: unitResult._0,
                                                                  className: css.errorMessage
                                                                }) : null;
                                                          var domId$3 = volumeDomId("description", [
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var result$3 = form.configurationVolumeDescriptionResult([
                                                                configurationIndex,
                                                                volumeIndex
                                                              ]);
                                                          var tmp$10;
                                                          tmp$10 = result$3 !== undefined && result$3.TAG !== "Ok" ? css.errorLabel : "";
                                                          var tmp$11;
                                                          tmp$11 = result$3 !== undefined && result$3.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                  children: result$3._0,
                                                                  className: css.errorMessage
                                                                }) : null;
                                                          return JsxRuntime.jsxs(ProjectWizardPanel.HeadlessSectionWithDeleteControl.make, {
                                                                      deleteControl: match.length !== 1 ? (function () {
                                                                            form.removeConfigurationVolume([
                                                                                  configurationIndex,
                                                                                  volumeIndex
                                                                                ]);
                                                                            persistDraft([
                                                                                  CreateCloudProjectIaasCloudServersConfigurationForm.Volume.remove(form.input, [
                                                                                        configurationIndex,
                                                                                        volumeIndex
                                                                                      ]),
                                                                                  context
                                                                                ]);
                                                                          }) : undefined,
                                                                      children: [
                                                                        JsxRuntime.jsxs("div", {
                                                                              children: [
                                                                                JsxRuntime.jsxs("div", {
                                                                                      children: [
                                                                                        JsxRuntime.jsx(Label.make, {
                                                                                              forId: domId,
                                                                                              status: result,
                                                                                              className: Cx.cx([
                                                                                                    css.label,
                                                                                                    css.requiredLabel,
                                                                                                    tmp
                                                                                                  ]),
                                                                                              children: "Disk/Storage Type"
                                                                                            }),
                                                                                        JsxRuntime.jsxs(Select.make, {
                                                                                              id: domId,
                                                                                              value: volume.diskType,
                                                                                              status: Belt_Option.map(result, (function (x) {
                                                                                                      if (x.TAG === "Ok") {
                                                                                                        return "Valid";
                                                                                                      } else {
                                                                                                        return "Error";
                                                                                                      }
                                                                                                    })),
                                                                                              className: css.input,
                                                                                              wrapperClassName: css.selectWrapper,
                                                                                              iconSize: "XXS",
                                                                                              iconColor: "GrayText",
                                                                                              onChange: (function ($$event) {
                                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.diskTypes, (function (x) {
                                                                                                              return ReactForm.value($$event) === x;
                                                                                                            })), "");
                                                                                                  form.updateConfigurationVolumeDiskType([
                                                                                                        configurationIndex,
                                                                                                        volumeIndex
                                                                                                      ], (function (input, value) {
                                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(input, [
                                                                                                                      configurationIndex,
                                                                                                                      volumeIndex
                                                                                                                    ], (function (volume) {
                                                                                                                        return {
                                                                                                                                key: volume.key,
                                                                                                                                diskType: value,
                                                                                                                                diskSize: volume.diskSize,
                                                                                                                                maxIops: volume.maxIops,
                                                                                                                                throughput: volume.throughput,
                                                                                                                                throughputUnit: volume.throughputUnit,
                                                                                                                                description: volume.description
                                                                                                                              };
                                                                                                                      }));
                                                                                                        }), value);
                                                                                                  persistDraft([
                                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(form.input, [
                                                                                                              configurationIndex,
                                                                                                              volumeIndex
                                                                                                            ], (function (volume) {
                                                                                                                return {
                                                                                                                        key: volume.key,
                                                                                                                        diskType: value,
                                                                                                                        diskSize: volume.diskSize,
                                                                                                                        maxIops: volume.maxIops,
                                                                                                                        throughput: volume.throughput,
                                                                                                                        throughputUnit: volume.throughputUnit,
                                                                                                                        description: volume.description
                                                                                                                      };
                                                                                                              })),
                                                                                                        context
                                                                                                      ]);
                                                                                                }),
                                                                                              onBlur: (function (param) {
                                                                                                  form.blurConfigurationVolumeDiskType([
                                                                                                        configurationIndex,
                                                                                                        volumeIndex
                                                                                                      ]);
                                                                                                }),
                                                                                              children: [
                                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                      children: "Select..."
                                                                                                    }),
                                                                                                Belt_Array.map(options.diskTypes, (function (diskType) {
                                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                                    value: diskType,
                                                                                                                    children: diskType
                                                                                                                  }, diskType);
                                                                                                      }))
                                                                                              ]
                                                                                            }),
                                                                                        tmp$1
                                                                                      ],
                                                                                      className: css.field
                                                                                    }),
                                                                                JsxRuntime.jsxs("div", {
                                                                                      children: [
                                                                                        JsxRuntime.jsx(Label.make, {
                                                                                              forId: domId$1,
                                                                                              status: result$1,
                                                                                              className: Cx.cx([
                                                                                                    css.label,
                                                                                                    css.requiredLabel,
                                                                                                    tmp$2
                                                                                                  ]),
                                                                                              children: "Disk/Storage Size"
                                                                                            }),
                                                                                        JsxRuntime.jsxs(Select.make, {
                                                                                              id: domId$1,
                                                                                              value: volume.diskSize,
                                                                                              status: Belt_Option.map(result$1, (function (x) {
                                                                                                      if (x.TAG === "Ok") {
                                                                                                        return "Valid";
                                                                                                      } else {
                                                                                                        return "Error";
                                                                                                      }
                                                                                                    })),
                                                                                              className: css.input,
                                                                                              wrapperClassName: css.selectWrapper,
                                                                                              iconSize: "XXS",
                                                                                              iconColor: "GrayText",
                                                                                              onChange: (function ($$event) {
                                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.diskSizes, (function (x) {
                                                                                                              return ReactForm.value($$event) === x;
                                                                                                            })), "");
                                                                                                  form.updateConfigurationVolumeDiskSize([
                                                                                                        configurationIndex,
                                                                                                        volumeIndex
                                                                                                      ], (function (input, value) {
                                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(input, [
                                                                                                                      configurationIndex,
                                                                                                                      volumeIndex
                                                                                                                    ], (function (volume) {
                                                                                                                        return {
                                                                                                                                key: volume.key,
                                                                                                                                diskType: volume.diskType,
                                                                                                                                diskSize: value,
                                                                                                                                maxIops: volume.maxIops,
                                                                                                                                throughput: volume.throughput,
                                                                                                                                throughputUnit: volume.throughputUnit,
                                                                                                                                description: volume.description
                                                                                                                              };
                                                                                                                      }));
                                                                                                        }), value);
                                                                                                  persistDraft([
                                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(form.input, [
                                                                                                              configurationIndex,
                                                                                                              volumeIndex
                                                                                                            ], (function (volume) {
                                                                                                                return {
                                                                                                                        key: volume.key,
                                                                                                                        diskType: volume.diskType,
                                                                                                                        diskSize: value,
                                                                                                                        maxIops: volume.maxIops,
                                                                                                                        throughput: volume.throughput,
                                                                                                                        throughputUnit: volume.throughputUnit,
                                                                                                                        description: volume.description
                                                                                                                      };
                                                                                                              })),
                                                                                                        context
                                                                                                      ]);
                                                                                                }),
                                                                                              onBlur: (function (param) {
                                                                                                  form.blurConfigurationVolumeDiskSize([
                                                                                                        configurationIndex,
                                                                                                        volumeIndex
                                                                                                      ]);
                                                                                                }),
                                                                                              children: [
                                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                      children: "Select..."
                                                                                                    }),
                                                                                                Belt_Array.map(options.diskSizes, (function (diskSize) {
                                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                                    value: diskSize,
                                                                                                                    children: diskSize
                                                                                                                  }, diskSize);
                                                                                                      }))
                                                                                              ]
                                                                                            }),
                                                                                        tmp$3
                                                                                      ],
                                                                                      className: css.field
                                                                                    }),
                                                                                JsxRuntime.jsxs("div", {
                                                                                      children: [
                                                                                        JsxRuntime.jsx(Label.make, {
                                                                                              forId: domId$2,
                                                                                              status: result$2,
                                                                                              className: Cx.cx([
                                                                                                    css.label,
                                                                                                    tmp$4
                                                                                                  ]),
                                                                                              children: "Max IOPS"
                                                                                            }),
                                                                                        JsxRuntime.jsx(TextField.make, {
                                                                                              id: domId$2,
                                                                                              value: Belt_Option.mapWithDefault(volume.maxIops, "", (function (prim) {
                                                                                                      return String(prim);
                                                                                                    })),
                                                                                              status: Belt_Option.flatMap(result$2, (function (x) {
                                                                                                      if (x.TAG === "Ok") {
                                                                                                        if (x._0 !== undefined) {
                                                                                                          return "Valid";
                                                                                                        } else {
                                                                                                          return ;
                                                                                                        }
                                                                                                      } else {
                                                                                                        return "Error";
                                                                                                      }
                                                                                                    })),
                                                                                              className: css.input,
                                                                                              onBlur: (function (param) {
                                                                                                  form.blurConfigurationVolumeMaxIops([
                                                                                                        configurationIndex,
                                                                                                        volumeIndex
                                                                                                      ]);
                                                                                                }),
                                                                                              onChange: (function ($$event) {
                                                                                                  var x = ReactForm.value($$event);
                                                                                                  var value;
                                                                                                  if (x === "") {
                                                                                                    value = {
                                                                                                      TAG: "Ok",
                                                                                                      _0: undefined
                                                                                                    };
                                                                                                  } else {
                                                                                                    var n = Belt_Int.fromString(x);
                                                                                                    value = n !== undefined && String(n) === x ? ({
                                                                                                          TAG: "Ok",
                                                                                                          _0: n
                                                                                                        }) : ({
                                                                                                          TAG: "Error",
                                                                                                          _0: undefined
                                                                                                        });
                                                                                                  }
                                                                                                  if (value.TAG !== "Ok") {
                                                                                                    return ;
                                                                                                  }
                                                                                                  var value$1 = value._0;
                                                                                                  form.updateConfigurationVolumeMaxIops([
                                                                                                        configurationIndex,
                                                                                                        volumeIndex
                                                                                                      ], (function (input, value) {
                                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(input, [
                                                                                                                      configurationIndex,
                                                                                                                      volumeIndex
                                                                                                                    ], (function (volume) {
                                                                                                                        return {
                                                                                                                                key: volume.key,
                                                                                                                                diskType: volume.diskType,
                                                                                                                                diskSize: volume.diskSize,
                                                                                                                                maxIops: value,
                                                                                                                                throughput: volume.throughput,
                                                                                                                                throughputUnit: volume.throughputUnit,
                                                                                                                                description: volume.description
                                                                                                                              };
                                                                                                                      }));
                                                                                                        }), value$1);
                                                                                                  persistDraft([
                                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(form.input, [
                                                                                                              configurationIndex,
                                                                                                              volumeIndex
                                                                                                            ], (function (volume) {
                                                                                                                return {
                                                                                                                        key: volume.key,
                                                                                                                        diskType: volume.diskType,
                                                                                                                        diskSize: volume.diskSize,
                                                                                                                        maxIops: value$1,
                                                                                                                        throughput: volume.throughput,
                                                                                                                        throughputUnit: volume.throughputUnit,
                                                                                                                        description: volume.description
                                                                                                                      };
                                                                                                              })),
                                                                                                        context
                                                                                                      ]);
                                                                                                })
                                                                                            }),
                                                                                        tmp$5
                                                                                      ],
                                                                                      className: css.field
                                                                                    }),
                                                                                JsxRuntime.jsx("div", {
                                                                                      children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                                                            children: [
                                                                                              JsxRuntime.jsx(Label.make, {
                                                                                                    forId: valueDomId,
                                                                                                    status: tmp$6,
                                                                                                    className: Cx.cx([
                                                                                                          css.label,
                                                                                                          tmp$7
                                                                                                        ]),
                                                                                                    children: "Throughput"
                                                                                                  }),
                                                                                              JsxRuntime.jsxs("div", {
                                                                                                    children: [
                                                                                                      JsxRuntime.jsxs("div", {
                                                                                                            children: [
                                                                                                              JsxRuntime.jsx(TextField.make, {
                                                                                                                    id: valueDomId,
                                                                                                                    value: Belt_Option.mapWithDefault(volume.throughput, "", (function (prim) {
                                                                                                                            return String(prim);
                                                                                                                          })),
                                                                                                                    status: Belt_Option.flatMap(valueResult, (function (x) {
                                                                                                                            if (x.TAG === "Ok") {
                                                                                                                              if (x._0 !== undefined) {
                                                                                                                                return "Valid";
                                                                                                                              } else {
                                                                                                                                return ;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              return "Error";
                                                                                                                            }
                                                                                                                          })),
                                                                                                                    className: Cx.cx([
                                                                                                                          css.input,
                                                                                                                          css.inputWithUnitValue
                                                                                                                        ]),
                                                                                                                    onBlur: (function (param) {
                                                                                                                        form.blurConfigurationVolumeThroughput([
                                                                                                                              configurationIndex,
                                                                                                                              volumeIndex
                                                                                                                            ]);
                                                                                                                      }),
                                                                                                                    onChange: (function ($$event) {
                                                                                                                        var x = ReactForm.value($$event);
                                                                                                                        var value;
                                                                                                                        if (x === "") {
                                                                                                                          value = {
                                                                                                                            TAG: "Ok",
                                                                                                                            _0: undefined
                                                                                                                          };
                                                                                                                        } else {
                                                                                                                          var n = Belt_Int.fromString(x);
                                                                                                                          value = n !== undefined && String(n) === x ? ({
                                                                                                                                TAG: "Ok",
                                                                                                                                _0: n
                                                                                                                              }) : ({
                                                                                                                                TAG: "Error",
                                                                                                                                _0: undefined
                                                                                                                              });
                                                                                                                        }
                                                                                                                        if (value.TAG !== "Ok") {
                                                                                                                          return ;
                                                                                                                        }
                                                                                                                        var value$1 = value._0;
                                                                                                                        form.updateConfigurationVolumeThroughput([
                                                                                                                              configurationIndex,
                                                                                                                              volumeIndex
                                                                                                                            ], (function (input, value) {
                                                                                                                                return CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(input, [
                                                                                                                                            configurationIndex,
                                                                                                                                            volumeIndex
                                                                                                                                          ], (function (volume) {
                                                                                                                                              return {
                                                                                                                                                      key: volume.key,
                                                                                                                                                      diskType: volume.diskType,
                                                                                                                                                      diskSize: volume.diskSize,
                                                                                                                                                      maxIops: volume.maxIops,
                                                                                                                                                      throughput: value,
                                                                                                                                                      throughputUnit: volume.throughputUnit,
                                                                                                                                                      description: volume.description
                                                                                                                                                    };
                                                                                                                                            }));
                                                                                                                              }), value$1);
                                                                                                                        persistDraft([
                                                                                                                              CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(form.input, [
                                                                                                                                    configurationIndex,
                                                                                                                                    volumeIndex
                                                                                                                                  ], (function (volume) {
                                                                                                                                      return {
                                                                                                                                              key: volume.key,
                                                                                                                                              diskType: volume.diskType,
                                                                                                                                              diskSize: volume.diskSize,
                                                                                                                                              maxIops: volume.maxIops,
                                                                                                                                              throughput: value$1,
                                                                                                                                              throughputUnit: volume.throughputUnit,
                                                                                                                                              description: volume.description
                                                                                                                                            };
                                                                                                                                    })),
                                                                                                                              context
                                                                                                                            ]);
                                                                                                                      })
                                                                                                                  }),
                                                                                                              tmp$8
                                                                                                            ],
                                                                                                            className: css.inputWithUnitValueContainer
                                                                                                          }),
                                                                                                      JsxRuntime.jsxs("div", {
                                                                                                            children: [
                                                                                                              JsxRuntime.jsxs(Select.make, {
                                                                                                                    id: unitDomId,
                                                                                                                    value: volume.throughputUnit,
                                                                                                                    status: Belt_Option.map(unitResult, (function (x) {
                                                                                                                            if (x.TAG === "Ok") {
                                                                                                                              return "Valid";
                                                                                                                            } else {
                                                                                                                              return "Error";
                                                                                                                            }
                                                                                                                          })),
                                                                                                                    className: Cx.cx([
                                                                                                                          css.input,
                                                                                                                          css.inputWithUnitUnit
                                                                                                                        ]),
                                                                                                                    wrapperClassName: css.unitSelectWrapper,
                                                                                                                    iconSize: "XXS",
                                                                                                                    iconColor: "GrayText",
                                                                                                                    onChange: (function ($$event) {
                                                                                                                        var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.throughputUnits, (function (x) {
                                                                                                                                    return ReactForm.value($$event) === x;
                                                                                                                                  })), "");
                                                                                                                        form.updateConfigurationVolumeThroughputUnit([
                                                                                                                              configurationIndex,
                                                                                                                              volumeIndex
                                                                                                                            ], (function (input, value) {
                                                                                                                                return CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(input, [
                                                                                                                                            configurationIndex,
                                                                                                                                            volumeIndex
                                                                                                                                          ], (function (volume) {
                                                                                                                                              return {
                                                                                                                                                      key: volume.key,
                                                                                                                                                      diskType: volume.diskType,
                                                                                                                                                      diskSize: volume.diskSize,
                                                                                                                                                      maxIops: volume.maxIops,
                                                                                                                                                      throughput: volume.throughput,
                                                                                                                                                      throughputUnit: value,
                                                                                                                                                      description: volume.description
                                                                                                                                                    };
                                                                                                                                            }));
                                                                                                                              }), value);
                                                                                                                        persistDraft([
                                                                                                                              CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(form.input, [
                                                                                                                                    configurationIndex,
                                                                                                                                    volumeIndex
                                                                                                                                  ], (function (volume) {
                                                                                                                                      return {
                                                                                                                                              key: volume.key,
                                                                                                                                              diskType: volume.diskType,
                                                                                                                                              diskSize: volume.diskSize,
                                                                                                                                              maxIops: volume.maxIops,
                                                                                                                                              throughput: volume.throughput,
                                                                                                                                              throughputUnit: value,
                                                                                                                                              description: volume.description
                                                                                                                                            };
                                                                                                                                    })),
                                                                                                                              context
                                                                                                                            ]);
                                                                                                                      }),
                                                                                                                    onBlur: (function (param) {
                                                                                                                        form.blurConfigurationVolumeThroughputUnit([
                                                                                                                              configurationIndex,
                                                                                                                              volumeIndex
                                                                                                                            ]);
                                                                                                                      }),
                                                                                                                    children: [
                                                                                                                      JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                                            selectable: true,
                                                                                                                            children: ""
                                                                                                                          }),
                                                                                                                      Belt_Array.map(options.throughputUnits, (function (x) {
                                                                                                                              return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                                                          value: x,
                                                                                                                                          children: x
                                                                                                                                        }, x);
                                                                                                                            }))
                                                                                                                    ]
                                                                                                                  }),
                                                                                                              tmp$9
                                                                                                            ],
                                                                                                            className: css.inputWithUnitUnitContainer
                                                                                                          })
                                                                                                    ],
                                                                                                    className: css.fieldWithUnit
                                                                                                  })
                                                                                            ]
                                                                                          }),
                                                                                      className: css.field
                                                                                    })
                                                                              ],
                                                                              className: Cx.cx([
                                                                                    css.fieldRow,
                                                                                    css.justified
                                                                                  ])
                                                                            }),
                                                                        JsxRuntime.jsx("div", {
                                                                              children: JsxRuntime.jsxs("div", {
                                                                                    children: [
                                                                                      JsxRuntime.jsx(Label.make, {
                                                                                            forId: domId$3,
                                                                                            status: result$3,
                                                                                            className: Cx.cx([
                                                                                                  css.label,
                                                                                                  tmp$10
                                                                                                ]),
                                                                                            children: "Description"
                                                                                          }),
                                                                                      JsxRuntime.jsx(Textarea.make, {
                                                                                            id: domId$3,
                                                                                            value: volume.description,
                                                                                            status: Belt_Option.flatMap(result$3, (function (x) {
                                                                                                    if (x.TAG === "Ok") {
                                                                                                      if (x._0 !== undefined) {
                                                                                                        return "Valid";
                                                                                                      } else {
                                                                                                        return ;
                                                                                                      }
                                                                                                    } else {
                                                                                                      return "Error";
                                                                                                    }
                                                                                                  })),
                                                                                            wrapperClassName: css.textarea,
                                                                                            onBlur: (function (param) {
                                                                                                form.blurConfigurationVolumeDescription([
                                                                                                      configurationIndex,
                                                                                                      volumeIndex
                                                                                                    ]);
                                                                                              }),
                                                                                            onChange: (function ($$event) {
                                                                                                var value = ReactForm.value($$event);
                                                                                                form.updateConfigurationVolumeDescription([
                                                                                                      configurationIndex,
                                                                                                      volumeIndex
                                                                                                    ], (function (input, value) {
                                                                                                        return CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(input, [
                                                                                                                    configurationIndex,
                                                                                                                    volumeIndex
                                                                                                                  ], (function (volume) {
                                                                                                                      return {
                                                                                                                              key: volume.key,
                                                                                                                              diskType: volume.diskType,
                                                                                                                              diskSize: volume.diskSize,
                                                                                                                              maxIops: volume.maxIops,
                                                                                                                              throughput: volume.throughput,
                                                                                                                              throughputUnit: volume.throughputUnit,
                                                                                                                              description: value
                                                                                                                            };
                                                                                                                    }));
                                                                                                      }), value);
                                                                                                persistDraft([
                                                                                                      CreateCloudProjectIaasCloudServersConfigurationForm.Volume.update(form.input, [
                                                                                                            configurationIndex,
                                                                                                            volumeIndex
                                                                                                          ], (function (volume) {
                                                                                                              return {
                                                                                                                      key: volume.key,
                                                                                                                      diskType: volume.diskType,
                                                                                                                      diskSize: volume.diskSize,
                                                                                                                      maxIops: volume.maxIops,
                                                                                                                      throughput: volume.throughput,
                                                                                                                      throughputUnit: volume.throughputUnit,
                                                                                                                      description: value
                                                                                                                    };
                                                                                                            })),
                                                                                                      context
                                                                                                    ]);
                                                                                              })
                                                                                          }),
                                                                                      tmp$11
                                                                                    ],
                                                                                    className: Cx.cx([
                                                                                          css.field,
                                                                                          css.expanded
                                                                                        ])
                                                                                  }),
                                                                              className: Cx.cx([
                                                                                    css.fieldRow,
                                                                                    css.justified
                                                                                  ])
                                                                            })
                                                                      ]
                                                                    }, volume.key);
                                                        })),
                                                  JsxRuntime.jsx(Button.make, {
                                                        size: "SM",
                                                        color: "Gray",
                                                        onClick: (function (param) {
                                                            form.addConfigurationVolume(CreateCloudProjectIaasCloudServersConfigurationForm.Volume.empty(), configurationIndex);
                                                          }),
                                                        children: "+ Add Volume"
                                                      })
                                                ]
                                              }) : null;
                                        var visibility$1 = state.featuresVisibility[configurationIndex];
                                        var tmp$26;
                                        tmp$26 = visibility$1 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                items: options.features,
                                                mobile: mobile,
                                                children: (function (feature) {
                                                    var domId$13 = domId("features-" + feature, configurationIndex);
                                                    return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                id: domId$13,
                                                                size: "MD",
                                                                checked: Belt_SetString.has(configuration.features, feature),
                                                                containerClassName: css.checkbox,
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.features, feature) : Belt_SetString.remove(configuration.features, feature);
                                                                    form.updateConfigurationFeatures(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  serverInstance: configuration.serverInstance,
                                                                                                  volumes: configuration.volumes,
                                                                                                  billingTypes: configuration.billingTypes,
                                                                                                  certifications: configuration.certifications,
                                                                                                  features: value,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          serverInstance: configuration.serverInstance,
                                                                                          volumes: configuration.volumes,
                                                                                          billingTypes: configuration.billingTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          features: value,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationFeatures(configurationIndex);
                                                                  }),
                                                                children: feature
                                                              }, domId$13);
                                                  })
                                              }) : null;
                                        var visibility$2 = state.certificationsVisibility[configurationIndex];
                                        var tmp$27;
                                        tmp$27 = visibility$2 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                items: options.certifications,
                                                mobile: mobile,
                                                children: (function (certification) {
                                                    var domId$13 = domId("certifications-" + certification, configurationIndex);
                                                    return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                id: domId$13,
                                                                size: "MD",
                                                                checked: Belt_SetString.has(configuration.certifications, certification),
                                                                containerClassName: css.checkbox,
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.certifications, certification) : Belt_SetString.remove(configuration.certifications, certification);
                                                                    form.updateConfigurationCertifications(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  serverInstance: configuration.serverInstance,
                                                                                                  volumes: configuration.volumes,
                                                                                                  billingTypes: configuration.billingTypes,
                                                                                                  certifications: value,
                                                                                                  features: configuration.features,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          serverInstance: configuration.serverInstance,
                                                                                          volumes: configuration.volumes,
                                                                                          billingTypes: configuration.billingTypes,
                                                                                          certifications: value,
                                                                                          features: configuration.features,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationCertifications(configurationIndex);
                                                                  }),
                                                                children: certification
                                                              }, domId$13);
                                                  })
                                              }) : null;
                                        var visibility$3 = state.billingTypesVisibility[configurationIndex];
                                        var tmp$28;
                                        tmp$28 = visibility$3 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                items: options.billingTypes,
                                                mobile: mobile,
                                                children: (function (billingType) {
                                                    var domId$13 = domId("billing-types-" + billingType, configurationIndex);
                                                    return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                id: domId$13,
                                                                size: "MD",
                                                                checked: Belt_SetString.has(configuration.billingTypes, billingType),
                                                                containerClassName: css.checkbox,
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.billingTypes, billingType) : Belt_SetString.remove(configuration.billingTypes, billingType);
                                                                    form.updateConfigurationBillingTypes(configurationIndex, (function (input, value) {
                                                                            return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          return {
                                                                                                  key: configuration.key,
                                                                                                  startDate: configuration.startDate,
                                                                                                  contractLength: configuration.contractLength,
                                                                                                  serverInstance: configuration.serverInstance,
                                                                                                  volumes: configuration.volumes,
                                                                                                  billingTypes: value,
                                                                                                  certifications: configuration.certifications,
                                                                                                  features: configuration.features,
                                                                                                  additionalRequirements: configuration.additionalRequirements
                                                                                                };
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          serverInstance: configuration.serverInstance,
                                                                                          volumes: configuration.volumes,
                                                                                          billingTypes: value,
                                                                                          certifications: configuration.certifications,
                                                                                          features: configuration.features,
                                                                                          additionalRequirements: configuration.additionalRequirements
                                                                                        };
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationBillingTypes(configurationIndex);
                                                                  }),
                                                                children: billingType
                                                              }, domId$13);
                                                  })
                                              }) : null;
                                        var visibility$4 = state.additionalRequirementsVisibility[configurationIndex];
                                        var tmp$29;
                                        if (visibility$4 === "Shown") {
                                          var domId$13 = domId("additional-requirements", configurationIndex);
                                          var result$12 = form.configurationAdditionalRequirementsResult(configurationIndex);
                                          var tmp$30;
                                          tmp$30 = result$12 !== undefined && result$12.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                  children: result$12._0,
                                                  className: css.errorMessage
                                                }) : null;
                                          tmp$29 = JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Textarea.make, {
                                                        id: domId$13,
                                                        value: configuration.additionalRequirements,
                                                        status: Belt_Option.flatMap(result$12, (function (x) {
                                                                if (x.TAG === "Ok") {
                                                                  if (x._0 !== undefined) {
                                                                    return "Valid";
                                                                  } else {
                                                                    return ;
                                                                  }
                                                                } else {
                                                                  return "Error";
                                                                }
                                                              })),
                                                        wrapperClassName: css.textarea,
                                                        onBlur: (function (param) {
                                                            form.blurConfigurationAdditionalRequirements(configurationIndex);
                                                          }),
                                                        onChange: (function ($$event) {
                                                            var value = ReactForm.value($$event);
                                                            form.updateConfigurationAdditionalRequirements(configurationIndex, (function (input, value) {
                                                                    return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                  return {
                                                                                          key: configuration.key,
                                                                                          startDate: configuration.startDate,
                                                                                          contractLength: configuration.contractLength,
                                                                                          serverInstance: configuration.serverInstance,
                                                                                          volumes: configuration.volumes,
                                                                                          billingTypes: configuration.billingTypes,
                                                                                          certifications: configuration.certifications,
                                                                                          features: configuration.features,
                                                                                          additionalRequirements: value
                                                                                        };
                                                                                }));
                                                                  }), value);
                                                            persistDraft([
                                                                  CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                          return {
                                                                                  key: configuration.key,
                                                                                  startDate: configuration.startDate,
                                                                                  contractLength: configuration.contractLength,
                                                                                  serverInstance: configuration.serverInstance,
                                                                                  volumes: configuration.volumes,
                                                                                  billingTypes: configuration.billingTypes,
                                                                                  certifications: configuration.certifications,
                                                                                  features: configuration.features,
                                                                                  additionalRequirements: value
                                                                                };
                                                                        })),
                                                                  context
                                                                ]);
                                                          })
                                                      }),
                                                  tmp$30
                                                ],
                                                className: Cx.cx([
                                                      css.fieldRow,
                                                      css.justified
                                                    ])
                                              });
                                        } else {
                                          tmp$29 = null;
                                        }
                                        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Label.make, {
                                                                      forId: domId$1,
                                                                      status: result,
                                                                      className: Cx.cx([
                                                                            css.label,
                                                                            css.requiredLabel,
                                                                            tmp$1
                                                                          ]),
                                                                      children: "Service Start Date"
                                                                    }),
                                                                JsxRuntime.jsx(DatePicker.make, {
                                                                      id: domId$1,
                                                                      value: Belt_Option.map(configuration.startDate, (function (prim) {
                                                                              return prim;
                                                                            })),
                                                                      fromMonth: Caml_option.some(AddDays(new Date(), 1)),
                                                                      disabledDays: [{
                                                                          from: new Date(0, 0),
                                                                          to: new Date()
                                                                        }],
                                                                      status: Belt_Option.map(result, (function (x) {
                                                                              if (x.TAG === "Ok") {
                                                                                return "Valid";
                                                                              } else {
                                                                                return "Error";
                                                                              }
                                                                            })),
                                                                      onDayChange: (function (date, param, param$1) {
                                                                          form.updateConfigurationStartDate(configurationIndex, (function (input, value) {
                                                                                  return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: value,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: configuration.serverInstance,
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              }));
                                                                                }), Caml_option.some(date));
                                                                          persistDraft([
                                                                                CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                        return {
                                                                                                key: configuration.key,
                                                                                                startDate: Caml_option.some(date),
                                                                                                contractLength: configuration.contractLength,
                                                                                                serverInstance: configuration.serverInstance,
                                                                                                volumes: configuration.volumes,
                                                                                                billingTypes: configuration.billingTypes,
                                                                                                certifications: configuration.certifications,
                                                                                                features: configuration.features,
                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                              };
                                                                                      })),
                                                                                context
                                                                              ]);
                                                                        }),
                                                                      onHide: (function () {
                                                                          form.blurConfigurationStartDate(configurationIndex);
                                                                        }),
                                                                      className: css.input
                                                                    }),
                                                                tmp$2
                                                              ],
                                                              className: css.field
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Label.make, {
                                                                      forId: domId$2,
                                                                      status: result$1,
                                                                      className: Cx.cx([
                                                                            css.label,
                                                                            css.requiredLabel,
                                                                            tmp$3
                                                                          ]),
                                                                      children: "Contract Length"
                                                                    }),
                                                                JsxRuntime.jsxs(Select.make, {
                                                                      id: domId$2,
                                                                      value: Belt_Option.mapWithDefault(configuration.contractLength, "", (function (cl) {
                                                                              return String(cl.value);
                                                                            })),
                                                                      status: Belt_Option.map(result$1, (function (x) {
                                                                              if (x.TAG === "Ok") {
                                                                                return "Valid";
                                                                              } else {
                                                                                return "Error";
                                                                              }
                                                                            })),
                                                                      className: css.input,
                                                                      wrapperClassName: css.selectWrapper,
                                                                      iconSize: "XXS",
                                                                      iconColor: "GrayText",
                                                                      onChange: (function ($$event) {
                                                                          var value = Belt_Array.getBy(options.contractLengths, (function (cl) {
                                                                                  return Belt_Option.mapWithDefault(Belt_Int.fromString(ReactForm.value($$event)), false, (function (value) {
                                                                                                return value === cl.value;
                                                                                              }));
                                                                                }));
                                                                          form.updateConfigurationContractLength(configurationIndex, (function (input, value) {
                                                                                  return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: value,
                                                                                                        serverInstance: configuration.serverInstance,
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              }));
                                                                                }), value);
                                                                          persistDraft([
                                                                                CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                        return {
                                                                                                key: configuration.key,
                                                                                                startDate: configuration.startDate,
                                                                                                contractLength: value,
                                                                                                serverInstance: configuration.serverInstance,
                                                                                                volumes: configuration.volumes,
                                                                                                billingTypes: configuration.billingTypes,
                                                                                                certifications: configuration.certifications,
                                                                                                features: configuration.features,
                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                              };
                                                                                      })),
                                                                                context
                                                                              ]);
                                                                        }),
                                                                      onBlur: (function (param) {
                                                                          form.blurConfigurationContractLength(configurationIndex);
                                                                        }),
                                                                      children: [
                                                                        JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                              children: "Select..."
                                                                            }),
                                                                        Belt_Array.map(options.contractLengths, (function (cl) {
                                                                                return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                            value: String(cl.value),
                                                                                            children: cl.label
                                                                                          }, String(cl.value));
                                                                              }))
                                                                      ]
                                                                    }),
                                                                tmp$4
                                                              ],
                                                              className: css.field
                                                            })
                                                      ],
                                                      className: Cx.cx([
                                                            css.fieldRow,
                                                            css.unfilled
                                                          ])
                                                    }),
                                                JsxRuntime.jsx(Hr.make, {}),
                                                JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
                                                      title: "Server Instances",
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$3,
                                                                              status: result$2,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$5
                                                                                  ]),
                                                                              children: "Number of VMs"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$3,
                                                                              value: Belt_Option.mapWithDefault(configuration.serverInstance.vmNumber, "", (function (prim) {
                                                                                      return String(prim);
                                                                                    })),
                                                                              status: Belt_Option.map(result$2, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceVmNumber(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var x = ReactForm.value($$event);
                                                                                  var value;
                                                                                  if (x === "") {
                                                                                    value = {
                                                                                      TAG: "Ok",
                                                                                      _0: undefined
                                                                                    };
                                                                                  } else {
                                                                                    var n = Belt_Int.fromString(x);
                                                                                    value = n !== undefined && String(n) === x ? ({
                                                                                          TAG: "Ok",
                                                                                          _0: n
                                                                                        }) : ({
                                                                                          TAG: "Error",
                                                                                          _0: undefined
                                                                                        });
                                                                                  }
                                                                                  if (value.TAG !== "Ok") {
                                                                                    return ;
                                                                                  }
                                                                                  var value$1 = value._0;
                                                                                  form.updateConfigurationServerInstanceVmNumber(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: init.ram,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: value
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value$1);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: init.ram,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: value$1
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$6
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$4,
                                                                              status: result$3,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$7
                                                                                  ]),
                                                                              children: "vCPUs"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$4,
                                                                              value: Belt_Option.mapWithDefault(configuration.serverInstance.cpu, "", (function (prim) {
                                                                                      return String(prim);
                                                                                    })),
                                                                              status: Belt_Option.map(result$3, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceCpu(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var x = ReactForm.value($$event);
                                                                                  var value;
                                                                                  if (x === "") {
                                                                                    value = {
                                                                                      TAG: "Ok",
                                                                                      _0: undefined
                                                                                    };
                                                                                  } else {
                                                                                    var n = Belt_Int.fromString(x);
                                                                                    value = n !== undefined && String(n) === x ? ({
                                                                                          TAG: "Ok",
                                                                                          _0: n
                                                                                        }) : ({
                                                                                          TAG: "Error",
                                                                                          _0: undefined
                                                                                        });
                                                                                  }
                                                                                  if (value.TAG !== "Ok") {
                                                                                    return ;
                                                                                  }
                                                                                  var value$1 = value._0;
                                                                                  form.updateConfigurationServerInstanceCpu(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: value,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: init.ram,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value$1);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: value$1,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: init.ram,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$8
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$5,
                                                                              status: result$4,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$9
                                                                                  ]),
                                                                              children: "RAM (GB)"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$5,
                                                                              value: configuration.serverInstance.ram,
                                                                              status: Belt_Option.map(result$4, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.ramSizes, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationServerInstanceRam(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: value,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: value,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceRam(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.ramSizes, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$10
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$6,
                                                                              status: result$5,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$11
                                                                                  ]),
                                                                              children: "Operating System"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$6,
                                                                              value: configuration.serverInstance.operatingSystem,
                                                                              status: Belt_Option.map(result$5, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.operatingSystems, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationServerInstanceOperatingSystem(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: value,
                                                                                                                  ram: init.ram,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: value,
                                                                                                          ram: init.ram,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceOperatingSystem(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.operatingSystems, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$12
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$7,
                                                                              status: result$6,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$13
                                                                                  ]),
                                                                              children: "Image"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$7,
                                                                              value: configuration.serverInstance.image,
                                                                              status: Belt_Option.map(result$6, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.images, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationServerInstanceImage(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: value,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: init.ram,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: value,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: init.ram,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceImage(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      selectable: true,
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.images, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$14
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$8,
                                                                              status: result$7,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$15
                                                                                  ]),
                                                                              children: "Hypervisor"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$8,
                                                                              value: configuration.serverInstance.hypervisor,
                                                                              status: Belt_Option.map(result$7, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.hypervisors, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationServerInstanceHypervisor(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: value,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: init.ram,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: value,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: init.ram,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceHypervisor(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      selectable: true,
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.hypervisors, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$16
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$9,
                                                                              status: result$8,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$17
                                                                                  ]),
                                                                              children: "Database"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$9,
                                                                              value: configuration.serverInstance.database,
                                                                              status: Belt_Option.map(result$8, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.databases, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationServerInstanceDatabase(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: value,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: init.ram,
                                                                                                                  region: init.region,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: value,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: init.ram,
                                                                                                          region: init.region,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceDatabase(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      selectable: true,
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.databases, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$18
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$10,
                                                                              status: result$9,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$19
                                                                                  ]),
                                                                              children: "Region"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$10,
                                                                              value: configuration.serverInstance.region,
                                                                              status: Belt_Option.map(result$9, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.regions, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationServerInstanceRegion(configurationIndex, (function (input, value) {
                                                                                          return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var init = configuration.serverInstance;
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                serverInstance: {
                                                                                                                  availability: init.availability,
                                                                                                                  cpu: init.cpu,
                                                                                                                  database: init.database,
                                                                                                                  description: init.description,
                                                                                                                  hypervisor: init.hypervisor,
                                                                                                                  image: init.image,
                                                                                                                  operatingSystem: init.operatingSystem,
                                                                                                                  ram: init.ram,
                                                                                                                  region: value,
                                                                                                                  type_: init.type_,
                                                                                                                  visibility: init.visibility,
                                                                                                                  vmNumber: init.vmNumber
                                                                                                                },
                                                                                                                volumes: configuration.volumes,
                                                                                                                billingTypes: configuration.billingTypes,
                                                                                                                certifications: configuration.certifications,
                                                                                                                features: configuration.features,
                                                                                                                additionalRequirements: configuration.additionalRequirements
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var init = configuration.serverInstance;
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        serverInstance: {
                                                                                                          availability: init.availability,
                                                                                                          cpu: init.cpu,
                                                                                                          database: init.database,
                                                                                                          description: init.description,
                                                                                                          hypervisor: init.hypervisor,
                                                                                                          image: init.image,
                                                                                                          operatingSystem: init.operatingSystem,
                                                                                                          ram: init.ram,
                                                                                                          region: value,
                                                                                                          type_: init.type_,
                                                                                                          visibility: init.visibility,
                                                                                                          vmNumber: init.vmNumber
                                                                                                        },
                                                                                                        volumes: configuration.volumes,
                                                                                                        billingTypes: configuration.billingTypes,
                                                                                                        certifications: configuration.certifications,
                                                                                                        features: configuration.features,
                                                                                                        additionalRequirements: configuration.additionalRequirements
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationServerInstanceRegion(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      selectable: true,
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.regions, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$20
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$11,
                                                                            status: result$10,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  tmp$21
                                                                                ]),
                                                                            children: "Network Performance"
                                                                          }),
                                                                      JsxRuntime.jsxs(Select.make, {
                                                                            id: domId$11,
                                                                            value: configuration.serverInstance.type_,
                                                                            status: Belt_Option.map(result$10, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      return "Valid";
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            className: css.input,
                                                                            wrapperClassName: css.selectWrapper,
                                                                            iconSize: "XXS",
                                                                            iconColor: "GrayText",
                                                                            onChange: (function ($$event) {
                                                                                var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.instanceTypes, (function (x) {
                                                                                            return ReactForm.value($$event) === x;
                                                                                          })), "");
                                                                                form.updateConfigurationServerInstanceType(configurationIndex, (function (input, value) {
                                                                                        return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      var init = configuration.serverInstance;
                                                                                                      return {
                                                                                                              key: configuration.key,
                                                                                                              startDate: configuration.startDate,
                                                                                                              contractLength: configuration.contractLength,
                                                                                                              serverInstance: {
                                                                                                                availability: init.availability,
                                                                                                                cpu: init.cpu,
                                                                                                                database: init.database,
                                                                                                                description: init.description,
                                                                                                                hypervisor: init.hypervisor,
                                                                                                                image: init.image,
                                                                                                                operatingSystem: init.operatingSystem,
                                                                                                                ram: init.ram,
                                                                                                                region: init.region,
                                                                                                                type_: value,
                                                                                                                visibility: init.visibility,
                                                                                                                vmNumber: init.vmNumber
                                                                                                              },
                                                                                                              volumes: configuration.volumes,
                                                                                                              billingTypes: configuration.billingTypes,
                                                                                                              certifications: configuration.certifications,
                                                                                                              features: configuration.features,
                                                                                                              additionalRequirements: configuration.additionalRequirements
                                                                                                            };
                                                                                                    }));
                                                                                      }), value);
                                                                                persistDraft([
                                                                                      CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              var init = configuration.serverInstance;
                                                                                              return {
                                                                                                      key: configuration.key,
                                                                                                      startDate: configuration.startDate,
                                                                                                      contractLength: configuration.contractLength,
                                                                                                      serverInstance: {
                                                                                                        availability: init.availability,
                                                                                                        cpu: init.cpu,
                                                                                                        database: init.database,
                                                                                                        description: init.description,
                                                                                                        hypervisor: init.hypervisor,
                                                                                                        image: init.image,
                                                                                                        operatingSystem: init.operatingSystem,
                                                                                                        ram: init.ram,
                                                                                                        region: init.region,
                                                                                                        type_: value,
                                                                                                        visibility: init.visibility,
                                                                                                        vmNumber: init.vmNumber
                                                                                                      },
                                                                                                      volumes: configuration.volumes,
                                                                                                      billingTypes: configuration.billingTypes,
                                                                                                      certifications: configuration.certifications,
                                                                                                      features: configuration.features,
                                                                                                      additionalRequirements: configuration.additionalRequirements
                                                                                                    };
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              }),
                                                                            onBlur: (function (param) {
                                                                                form.blurConfigurationServerInstanceType(configurationIndex);
                                                                              }),
                                                                            children: [
                                                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                    selectable: true,
                                                                                    children: "Select..."
                                                                                  }),
                                                                              Belt_Array.map(options.instanceTypes, (function (x) {
                                                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                  value: x,
                                                                                                  children: x
                                                                                                }, x);
                                                                                    }))
                                                                            ]
                                                                          }),
                                                                      tmp$22
                                                                    ],
                                                                    className: css.field
                                                                  }),
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$12,
                                                                            status: result$11,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  tmp$23
                                                                                ]),
                                                                            children: "Description"
                                                                          }),
                                                                      JsxRuntime.jsx(Textarea.make, {
                                                                            id: domId$12,
                                                                            value: configuration.serverInstance.description,
                                                                            status: Belt_Option.flatMap(result$11, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      if (x._0 !== undefined) {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return ;
                                                                                      }
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            wrapperClassName: css.textarea,
                                                                            onBlur: (function (param) {
                                                                                form.blurConfigurationServerInstanceDescription(configurationIndex);
                                                                              }),
                                                                            onChange: (function ($$event) {
                                                                                var value = ReactForm.value($$event);
                                                                                form.updateConfigurationServerInstanceDescription(configurationIndex, (function (input, value) {
                                                                                        return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      var init = configuration.serverInstance;
                                                                                                      return {
                                                                                                              key: configuration.key,
                                                                                                              startDate: configuration.startDate,
                                                                                                              contractLength: configuration.contractLength,
                                                                                                              serverInstance: {
                                                                                                                availability: init.availability,
                                                                                                                cpu: init.cpu,
                                                                                                                database: init.database,
                                                                                                                description: value,
                                                                                                                hypervisor: init.hypervisor,
                                                                                                                image: init.image,
                                                                                                                operatingSystem: init.operatingSystem,
                                                                                                                ram: init.ram,
                                                                                                                region: init.region,
                                                                                                                type_: init.type_,
                                                                                                                visibility: init.visibility,
                                                                                                                vmNumber: init.vmNumber
                                                                                                              },
                                                                                                              volumes: configuration.volumes,
                                                                                                              billingTypes: configuration.billingTypes,
                                                                                                              certifications: configuration.certifications,
                                                                                                              features: configuration.features,
                                                                                                              additionalRequirements: configuration.additionalRequirements
                                                                                                            };
                                                                                                    }));
                                                                                      }), value);
                                                                                persistDraft([
                                                                                      CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              var init = configuration.serverInstance;
                                                                                              return {
                                                                                                      key: configuration.key,
                                                                                                      startDate: configuration.startDate,
                                                                                                      contractLength: configuration.contractLength,
                                                                                                      serverInstance: {
                                                                                                        availability: init.availability,
                                                                                                        cpu: init.cpu,
                                                                                                        database: init.database,
                                                                                                        description: value,
                                                                                                        hypervisor: init.hypervisor,
                                                                                                        image: init.image,
                                                                                                        operatingSystem: init.operatingSystem,
                                                                                                        ram: init.ram,
                                                                                                        region: init.region,
                                                                                                        type_: init.type_,
                                                                                                        visibility: init.visibility,
                                                                                                        vmNumber: init.vmNumber
                                                                                                      },
                                                                                                      volumes: configuration.volumes,
                                                                                                      billingTypes: configuration.billingTypes,
                                                                                                      certifications: configuration.certifications,
                                                                                                      features: configuration.features,
                                                                                                      additionalRequirements: configuration.additionalRequirements
                                                                                                    };
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              })
                                                                          }),
                                                                      tmp$24
                                                                    ],
                                                                    className: Cx.cx([
                                                                          css.field,
                                                                          css.expanded
                                                                        ])
                                                                  }),
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: Belt_Array.map(options.instanceVisibility, (function (option) {
                                                                      var domId = serverInstanceDomId("visibility-" + option, configurationIndex);
                                                                      return JsxRuntime.jsxs("div", {
                                                                                  children: [
                                                                                    JsxRuntime.jsx(RadioButton.make, {
                                                                                          id: domId,
                                                                                          checked: configuration.serverInstance.visibility === option,
                                                                                          onChange: (function (param) {
                                                                                              form.updateConfigurationServerInstanceVisibility(configurationIndex, (function (input, value) {
                                                                                                      return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                                    var init = configuration.serverInstance;
                                                                                                                    return {
                                                                                                                            key: configuration.key,
                                                                                                                            startDate: configuration.startDate,
                                                                                                                            contractLength: configuration.contractLength,
                                                                                                                            serverInstance: {
                                                                                                                              availability: init.availability,
                                                                                                                              cpu: init.cpu,
                                                                                                                              database: init.database,
                                                                                                                              description: init.description,
                                                                                                                              hypervisor: init.hypervisor,
                                                                                                                              image: init.image,
                                                                                                                              operatingSystem: init.operatingSystem,
                                                                                                                              ram: init.ram,
                                                                                                                              region: init.region,
                                                                                                                              type_: init.type_,
                                                                                                                              visibility: value,
                                                                                                                              vmNumber: init.vmNumber
                                                                                                                            },
                                                                                                                            volumes: configuration.volumes,
                                                                                                                            billingTypes: configuration.billingTypes,
                                                                                                                            certifications: configuration.certifications,
                                                                                                                            features: configuration.features,
                                                                                                                            additionalRequirements: configuration.additionalRequirements
                                                                                                                          };
                                                                                                                  }));
                                                                                                    }), option);
                                                                                              persistDraft([
                                                                                                    CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                            var init = configuration.serverInstance;
                                                                                                            return {
                                                                                                                    key: configuration.key,
                                                                                                                    startDate: configuration.startDate,
                                                                                                                    contractLength: configuration.contractLength,
                                                                                                                    serverInstance: {
                                                                                                                      availability: init.availability,
                                                                                                                      cpu: init.cpu,
                                                                                                                      database: init.database,
                                                                                                                      description: init.description,
                                                                                                                      hypervisor: init.hypervisor,
                                                                                                                      image: init.image,
                                                                                                                      operatingSystem: init.operatingSystem,
                                                                                                                      ram: init.ram,
                                                                                                                      region: init.region,
                                                                                                                      type_: init.type_,
                                                                                                                      visibility: option,
                                                                                                                      vmNumber: init.vmNumber
                                                                                                                    },
                                                                                                                    volumes: configuration.volumes,
                                                                                                                    billingTypes: configuration.billingTypes,
                                                                                                                    certifications: configuration.certifications,
                                                                                                                    features: configuration.features,
                                                                                                                    additionalRequirements: configuration.additionalRequirements
                                                                                                                  };
                                                                                                          })),
                                                                                                    context
                                                                                                  ]);
                                                                                            })
                                                                                        }),
                                                                                    JsxRuntime.jsx(Label.make, {
                                                                                          forId: domId,
                                                                                          className: css.radioButtonLabel,
                                                                                          children: option
                                                                                        })
                                                                                  ],
                                                                                  className: css.radioButton
                                                                                }, option);
                                                                    })),
                                                              className: css.radioButtonsRow
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: Belt_Array.map(options.instanceAvailability, (function (option) {
                                                                      var domId = serverInstanceDomId("availability-" + option, configurationIndex);
                                                                      return JsxRuntime.jsxs("div", {
                                                                                  children: [
                                                                                    JsxRuntime.jsx(RadioButton.make, {
                                                                                          id: domId,
                                                                                          checked: configuration.serverInstance.availability === option,
                                                                                          onChange: (function (param) {
                                                                                              form.updateConfigurationServerInstanceAvailability(configurationIndex, (function (input, value) {
                                                                                                      return CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                                    var init = configuration.serverInstance;
                                                                                                                    return {
                                                                                                                            key: configuration.key,
                                                                                                                            startDate: configuration.startDate,
                                                                                                                            contractLength: configuration.contractLength,
                                                                                                                            serverInstance: {
                                                                                                                              availability: value,
                                                                                                                              cpu: init.cpu,
                                                                                                                              database: init.database,
                                                                                                                              description: init.description,
                                                                                                                              hypervisor: init.hypervisor,
                                                                                                                              image: init.image,
                                                                                                                              operatingSystem: init.operatingSystem,
                                                                                                                              ram: init.ram,
                                                                                                                              region: init.region,
                                                                                                                              type_: init.type_,
                                                                                                                              visibility: init.visibility,
                                                                                                                              vmNumber: init.vmNumber
                                                                                                                            },
                                                                                                                            volumes: configuration.volumes,
                                                                                                                            billingTypes: configuration.billingTypes,
                                                                                                                            certifications: configuration.certifications,
                                                                                                                            features: configuration.features,
                                                                                                                            additionalRequirements: configuration.additionalRequirements
                                                                                                                          };
                                                                                                                  }));
                                                                                                    }), option);
                                                                                              persistDraft([
                                                                                                    CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                            var init = configuration.serverInstance;
                                                                                                            return {
                                                                                                                    key: configuration.key,
                                                                                                                    startDate: configuration.startDate,
                                                                                                                    contractLength: configuration.contractLength,
                                                                                                                    serverInstance: {
                                                                                                                      availability: option,
                                                                                                                      cpu: init.cpu,
                                                                                                                      database: init.database,
                                                                                                                      description: init.description,
                                                                                                                      hypervisor: init.hypervisor,
                                                                                                                      image: init.image,
                                                                                                                      operatingSystem: init.operatingSystem,
                                                                                                                      ram: init.ram,
                                                                                                                      region: init.region,
                                                                                                                      type_: init.type_,
                                                                                                                      visibility: init.visibility,
                                                                                                                      vmNumber: init.vmNumber
                                                                                                                    },
                                                                                                                    volumes: configuration.volumes,
                                                                                                                    billingTypes: configuration.billingTypes,
                                                                                                                    certifications: configuration.certifications,
                                                                                                                    features: configuration.features,
                                                                                                                    additionalRequirements: configuration.additionalRequirements
                                                                                                                  };
                                                                                                          })),
                                                                                                    context
                                                                                                  ]);
                                                                                            })
                                                                                        }),
                                                                                    JsxRuntime.jsx(Label.make, {
                                                                                          forId: domId,
                                                                                          className: css.radioButtonLabel,
                                                                                          children: option
                                                                                        })
                                                                                  ],
                                                                                  className: css.radioButton
                                                                                }, option);
                                                                    })),
                                                              className: css.radioButtonsRow
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Volumes",
                                                      visibility: state.volumesVisibility[configurationIndex],
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleConfigurationVolumesVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$25
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Features",
                                                      visibility: visibility$1,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleConfigurationFeaturesVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$26
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Certifications",
                                                      visibility: visibility$2,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleConfigurationCertificationsVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$27
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Billing Types",
                                                      visibility: visibility$3,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleConfigurationBillingTypesVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$28
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Additional Requirements",
                                                      visibility: visibility$4,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleConfigurationAdditionalRequirementsVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$29
                                                    })
                                              ]
                                            });
                                      } else {
                                        tmp = null;
                                      }
                                      return JsxRuntime.jsx(ProjectWizardPanel.make, {
                                                  title: configurationTitle(form, configurationIndex),
                                                  visibility: visibility,
                                                  toggleVisibility: (function () {
                                                      dispatch({
                                                            TAG: "ToggleConfigurationPanelVisibility",
                                                            at: configurationIndex
                                                          });
                                                    }),
                                                  deleteControl: match.length !== 1 ? (function () {
                                                        form.removeConfiguration(configurationIndex);
                                                        dispatch({
                                                              TAG: "RemoveConfigurationPanel",
                                                              at: configurationIndex
                                                            });
                                                        persistDraft([
                                                              CreateCloudProjectIaasCloudServersConfigurationForm.Configuration.remove(form.input, configurationIndex),
                                                              context
                                                            ]);
                                                      }) : undefined,
                                                  children: tmp
                                                }, configuration.key);
                                    }))
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              buttonClassName: css.addAnotherButton,
                              onClick: (function (param) {
                                  dispatch("AddConfigurationPanel");
                                }),
                              children: "+ Add One More Cloud Configuration"
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 1 of 3",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: tmp,
                        previous: tmp$3,
                        continueLater: tmp$4
                      },
                      mobile: mobile
                    }),
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                tmp$5
              ]
            });
}

var Configuration;

var Volume;

var make = CreateCloudProjectIaasCloudServersConfiguration;

export {
  css ,
  Configuration ,
  Volume ,
  domId ,
  serverInstanceDomId ,
  volumeDomId ,
  configurationTitle ,
  Visibilities ,
  ValidateSaveAndContinueLaterForm ,
  Api$1 as Api,
  make ,
}
/* css Not a pure module */
