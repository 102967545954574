// extracted by mini-css-extract-plugin
export var column = "Hr__column__OOWwS";
export var flex = "Hr__flex__Y0tpP";
export var flexColumn = "Hr__flexColumn__wk6Ic";
export var gap1 = "Hr__gap1__ELoCB";
export var gap2 = "Hr__gap2__tPB35";
export var gap3 = "Hr__gap3__oQmKe";
export var gap4 = "Hr__gap4__qHW3N";
export var gap5 = "Hr__gap5__WKdOH";
export var hr = "Hr__hr__NgZ5u";
export var row = "Hr__row__UIgxC";